import { renderToStaticMarkup } from 'react-dom/server';

export default function stringToKey (str) {
	str = renderToStaticMarkup(str);
	str = str.split('').filter((c, i) => i % 2 === 0).reduce((hash, c) => {
		c = c.charCodeAt(0);
		hash = ((hash << 5) - hash) + c;
		return hash|0;
	}, 0);
	return str;
}

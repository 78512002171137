import css from './style.module.scss';
import ReactModal from 'react-modal';
import cls from '../../util/cls';

ReactModal.setAppElement('#__next');

export default function Modal ({
	isOpen,
	onRequestClose,
	children,
	right = false,
	blue = false,
	className,
	overlayClassName,
}) {
	return (
		<ReactModal
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			className={cls(css.modal, className, {
				[css.right]: right,
			})}
			overlayClassName={cls(css.overlay, overlayClassName, {
				[css.blue]: blue,
			})}
			closeTimeoutMS={300}
		>
			{children}
		</ReactModal>
	);
}

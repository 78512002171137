import Link from 'next/link';

export default function A ({
	href,
	children,
	animation = null,
	...props
}) {
	let El = 'a';

	if (animation) {
		El = animation.el ?? 'a';
		props = {...props, ...animation};
	}

	if (href.indexOf('://') === -1 && !href.startsWith('/'))
		href = '/' + href;

	return (
		<Link href={href} passHref>
			<El {...props}>
				{children}
			</El>
		</Link>
	);
}

import css from './style.module.scss';
import cls from '../../util/cls';

export default function Input ({
	El = 'label',
	label,
	name,
	type = 'text',
	defaultValue = null,
	wide = false,
	autoFocus = false,
	required = false,
	instructions = null,
	...props
}) {
	return (
		<El className={cls(css.label, { [css.wide]: wide })}>
			{label && <span>{label}</span>}
			<input
				type={type}
				name={name}
				defaultValue={defaultValue}
				autoFocus={autoFocus}
				required={required}
				{...props}
			/>
			{instructions && <small>{instructions}</small>}
		</El>
	);
}

import css from './style.module.scss';
import LineItem, { LINE_ITEM_FRAGMENT } from '../LineItem';
import Button from '../Button';
import cls from '../../util/cls';
import currency from '../../util/currency';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Events, Listen } from '../../util/Events';
import fetchGql, { gql } from '../../util/fetchGql';
import cloneDeep from 'lodash.clonedeep';
import useFetch from '../../hooks/useFetch';
import { CART_KEY } from './index';
import ItemsContext from '../../contexts/ItemsContext';
import CouponCode from '../CouponCode';
const DEFAULT_CART = { id: null, items: [], total: 0, tax: 0, delivery: 0 };

const DATA_CACHE = { data: null };

export const CART_FRAGMENT = gql`
    fragment Cart on Cart {
        id
        total
        tax
        delivery
	    method
        items {
            purchasableId
            qty
        }
	    couponCode
    }
`;

export default function Items ({
	readOnly = false,
	locked = false,
}) {
	const { cart, busy } = useContext(ItemsContext);

	const { data } = useFetch(gql`
        query GetCartVariants ($ids: [QueryArgument!]) {
            variants (id: $ids) {
                ...LineItem
            }
        }
        ${LINE_ITEM_FRAGMENT}
	`, {
		ids: cart?.items?.map(i => i.purchasableId),
	});

	const variantsById = useMemo(() => {
		if (data) DATA_CACHE.data = data;

		return DATA_CACHE.data?.variants?.reduce((a, b) => {
			a[b.id] = b;
			return a;
		}, {}) ?? {};
	}, [data, cart]);

	return (
		<>
			<ul className={css.items}>
				{cart?.items?.map((item, i) => (
					<LineItem
						key={i}
						{...item}
						variant={variantsById[item.purchasableId]}
						id={item.purchasableId}
						readOnly={readOnly}
					/>
				))}
			</ul>

			{!locked && (
				<CouponCode
					cartId={cart.id}
					defaultValue={cart.couponCode}
				/>
			)}

			<table className={cls(css.total, { [css.busy]: busy })}>
				<tbody>
				<tr>
					<th>Delivery {cart.method}</th>
					<td>{cart.delivery ? currency(cart.delivery) : 'FREE'}</td>
				</tr>
				<tr>
					<th>Tax</th>
					<td>{currency(cart.tax)}</td>
				</tr>
				<tr>
					<th>Total</th>
					<td>{currency(cart.total)}</td>
				</tr>
				</tbody>
			</table>
		</>
	);
}

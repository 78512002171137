import css from './style.module.scss';
import Wrap from '../Wrap';
import Image from 'next/image';
import { gql } from '../../util/fetchGql';
import { useContext } from 'react';
import DataContext from '../../contexts/DataContext';
import Seo from '../Seo';
import formDataToObj from '../../util/formDataToObj';

export const AUTH_LAYOUT_FRAGMENT = gql`
	fragment AuthLayout on Query {
		authLayout: globalSet (handle: "authentication") {
			... on authentication_GlobalSet {
				image {
					url @transform(
						width: 1500
						height: 1500
					)
				}
			}
		}
	}
`;

export default function AuthLayout ({ title, children, onSubmit, clearAfterSubmit = false }) {
	const { authLayout } = useContext(DataContext);

	const _onSubmit = async e => {
		e.preventDefault();
		onSubmit && await onSubmit(formDataToObj(new FormData(e.target)));
		if (clearAfterSubmit) e.target.reset();
	};

	const img = authLayout?.image?.[0];

	return (
		<Wrap wide grid className={css.layout}>
			<Seo title={`${title} - Reference Audio`} />

			<figure className={css.image}>
				{img && (
					<Image unoptimized
						src={img.url}
						width={840}
						height={840}
						alt=""
					/>
				)}
			</figure>
			<div className={css.content}>
				<form onSubmit={_onSubmit} className={css.form}>
					{children}
				</form>
			</div>
		</Wrap>
	);
}

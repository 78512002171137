import { Fragment, useEffect } from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';

const TRACKING_ID = 'G-M7NSGS5BP0';

/**
 * Future Tam Note:
 * This has to be imported and re-exported in `_app.js` for it to actually do
 * anything
 *
 * @param id
 * @param name
 * @param label
 * @param value
 */
export function reportWebVitals ({ id, name, label, value }) {
	if (!process.browser) return;

	// Use `window.gtag` if you initialized Google Analytics as this example:
	// https://github.com/vercel/next.js/blob/canary/examples/with-google-analytics/pages/_document.js
	window.gtag && window.gtag('event', name, {
		event_category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
		value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
		event_label: id, // id unique to current page load
		non_interaction: true, // avoids affecting bounce rate.
	});
}

export default function Tracking () {
	const router = useRouter();

	useEffect(() => {
		const handleRouteChange = url => {
			window.gtag('config', TRACKING_ID, {
				'anonymize_ip': true,
				'page_path': url,
			});
		};
		router.events.on('routeChangeComplete', handleRouteChange);
		return () => {
			router.events.off('routeChangeComplete', handleRouteChange)
		};
	}, [router.events]);

	return (
		<Fragment key="tracking">
			<Script
				async
				src="https://www.googletagmanager.com/gtag/js?id=G-M7NSGS5BP0"
			/>
			{/* language=JavaScript */}
			<Script>{`
				window.dataLayer = window.dataLayer || [];
				function gtag(){dataLayer.push(arguments);}
				gtag('js', new Date());
				gtag('config', '${TRACKING_ID}', { 'anonymize_ip': true });
			`}</Script>
		</Fragment>
	);
}
/*
<script async src="https://www.googletagmanager.com/gtag/js?id=G-M7NSGS5BP0"></script> <script>   window.dataLayer = window.dataLayer || [];   function gtag(){dataLayer.push(arguments);}   gtag('js', new Date());   gtag('config', 'G-M7NSGS5BP0'); </script>*/

import css from './style.module.scss';
import Button from '../Button';
import { useState } from 'react';
import Input from '../Input';
import fetchGql, { gql } from '../../util/fetchGql';
import { Emit, Events } from '../../util/Events';

export default function CouponCode ({ cartId, defaultValue }) {
	const [open, setOpen] = useState(!!defaultValue)
		, [busy, setBusy] = useState(false)
		, [error, setError] = useState(null);

	const onAddClick = () => setOpen(true);
	const onSubmit = async e => {
		e.preventDefault();
		setBusy(true);
		setError(null);

		const code = e.target.elements.code.value.trim() ?? null;

		const [, errors] = await fetchGql(gql`
			mutation UpdateCart (
				$id: ID!
				$code: String
			) {
				updateCart (
					cartId: $id
					input: {
						couponCode: $code
					}
				) { id }
			}
		`, {
			id: cartId,
			code,
		}, null, true);

		setBusy(false);
		if (!code) setOpen(false);

		if (errors?.length > 0) {
			setError(errors[0].message);
			e.target.reset();
		} else {
			Emit(Events.RefetchCart);
		}
	};

	return (
		<form className={css.discount} onSubmit={onSubmit}>
			{error && <div className={css.error}>{error}</div>}
			{open ? defaultValue ? (
				<div className={css.active}>
					<input type="hidden" name="code" value="" />
					<div>
						<small>Discount</small>{defaultValue}
					</div>
					<Button blue text small type="submit" busy={busy}>Remove</Button>
				</div>
			) : (
				<label>
					<Input
						El="span"
						placeholder="Discount Code"
						name="code"
						autoFocus
					/>
					<Button blue text small type="submit" busy={busy}>Add</Button>
				</label>
			) : (
				<Button blue text small onClick={onAddClick}>+ Discount Code</Button>
			)}
		</form>
	);
}

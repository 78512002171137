import css from './style.module.scss';
import cls from '../../util/cls';

export default function Wrap ({
	className,
	wide = false,
	grid = false,
	children,
	El = 'section',
	...props
}) {
	return (
		<El
			className={cls(css.wrap, className, {
				[css.wide]: wide,
				[css.grid]: grid,
			})}
			{...props}
		>
			{children}
		</El>
	);
}

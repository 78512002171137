import css from './style.module.scss';
import IconDismiss from '../../svg/icon-dismiss.svg';
import Wrap from '../Wrap';
import stringToKey from '../../util/stringToKey';
import { useState } from 'react';
import { gql } from '../../util/fetchGql';
import md from '../../util/md';

export const ANNOUNCEMENT_FRAGMENT = gql`
	fragment Announcement on Query {
        announcement: globalSet(handle: "header") {
			... on header_GlobalSet {
				text: announcement
				enabled: enableAnnouncement
			}
		}
	}
`;

export default function Announcement ({ text, enabled }) {
	const [hidden, setHidden] = useState(() => {
		if (!enabled || text.trim() === '')
			return true;

		const key = stringToKey(text).toString();
		const oldKey = localStorage.getItem('announcement');
		return oldKey === key;
	});

	const onHideClick = () => {
		setHidden(true);
		localStorage.setItem('announcement', stringToKey(text));
	};

	return hidden ? null : (
		<Wrap wide className={css.announcement}>
			<div className={css.marquee}>
				{md(text)}
				{md(text)}
			</div>
			<button type="button" className={css.dismiss} onClick={onHideClick}>
				<IconDismiss />
			</button>
		</Wrap>
	);
}

import ItemsContext from '../contexts/ItemsContext';
import { useEffect, useState } from 'react';
import { CART_KEY } from './Cart';
import { Emit, Events, Listen } from '../util/Events';
import fetchGql, { gql } from '../util/fetchGql';
import cloneDeep from 'lodash.clonedeep';
import { CART_FRAGMENT } from './Cart/Items';

const DEFAULT_CART = { id: null, items: [], total: 0, tax: 0, delivery: 0 };

export default function ItemsProvider ({ children }) {
	const [busy, _setBusy] = useState(false);
	const [cart, _setCart] = useState(process.browser ? JSON.parse(localStorage.getItem(CART_KEY) ?? JSON.stringify(DEFAULT_CART)) : DEFAULT_CART)

	const setCart = callback => _setCart(old => {
		const cart = callback(old);
		localStorage.setItem(CART_KEY, JSON.stringify(cart));

		return cart;
	});

	useEffect(() => Listen(
		Events.RefetchCart,
		async () => {
			_setBusy(true);
			const data = await fetchGql(gql`
                query GetOrder ($id: ID!) {
                    cart (id: $id) {
                        ...Cart
                    }
                }
                ${CART_FRAGMENT}
			`, {
				id: cart?.id,
			});

			setCart(() => data.cart);
			_setBusy(false);
		}
	));

	useEffect(() => Listen(
		Events.AddToCart,
		async id => {
			_setBusy(true);
			const data = await fetchGql(gql`
                mutation AddToCart (
                    $cartId: ID
                    $purchasableId: ID!
                ) {
                    addToCart (
                        cartId: $cartId
                        purchasableId: $purchasableId
                    ) {
                        ...Cart
                    }
                }
                ${CART_FRAGMENT}
			`, {
				cartId: cart?.id,
				purchasableId: id,
			});

			setCart(() => data.addToCart);
			_setBusy(false);
		}
	));

	useEffect(() => Listen(
		Events.IncreaseQty|Events.DecreaseQty,
		async (id, _event) => {
			// Eager
			setCart(old => {
				const cart = cloneDeep(old ?? DEFAULT_CART);

				for (let i = 0, l = cart?.items?.length; i < l; i++) {
					const item = cart.items[i];

					if (item.purchasableId !== id) continue;

					if (_event === Events.IncreaseQty)
						item.qty += 1;
					else
						item.qty -= 1;

					if (cart.items[i].qty <= 0)
						cart.items.splice(i, 1);

					return cart;
				}

				return cart;
			});

			// Actual
			_setBusy(true);
			const data = await fetchGql(gql`
                mutation AddToCart (
                    $cartId: ID
                    $purchasableId: ID!
                    $qty: Int
                ) {
                    addToCart (
                        cartId: $cartId
                        purchasableId: $purchasableId
                        qty: $qty
                    ) {
                        ...Cart
                    }
                }
                ${CART_FRAGMENT}
			`, {
				cartId: cart?.id,
				purchasableId: id,
				qty: _event === Events.IncreaseQty ? 1 : -1,
			});

			setCart(() => data.addToCart);
			_setBusy(false);
		}
	));

	// Refetch cart on load
	useEffect(() => Emit(Events.RefetchCart), []);

	return (
		<ItemsContext.Provider value={{ cart, setCart, busy, setBusy: _setBusy }}>
			{children}
		</ItemsContext.Provider>
	);
}

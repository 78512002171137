/**
 * Converts the given FormData to an object
 *
 * @param {FormData} formData
 * @returns {string}
 */
export default function formDataToObj (formData) {
	const object = {};

	formData.forEach((value, key) => {
		if(!Reflect.has(object, key)){
			object[key] = value;
			return;
		}

		if(!Array.isArray(object[key]))
			object[key] = [object[key]];

		object[key].push(value);
	});

	return object;
}

import css from './style.module.scss';
import A from '../A';
import cls from '../../util/cls';
import { gql } from '../../util/fetchGql';

export const TYPED_LINK_FRAGMENT = gql`
    fragment TypedLink on linkField_Link {
        children: customText
        href: url
        element {
            title
            uri
        }
    }
`;


export default function Button ({
	El = A,
	type = 'button',
	href = null,
	element = null,
	className,
	children,
	wide = false,
	white = false,
	blue = false,
	grey = false,
	text = false,
	slim = false,
	small = false,
	rounded = false,
	outline = false,
	busy = false,
	...props
}) {
	href = element?.uri ?? href;

	if (href && href.indexOf('://') === -1 && href.indexOf('mailto:') === -1)
		href = '/' + href.replace(/^\//, '');

	children = children ?? element?.title;

	if (!children)
		return null;

	if (!href)
		El = 'button';

	return (
		<El
			className={cls(css.btn, className, {
				[css.wide]: wide,
				[css.white]: white,
				[css.blue]: blue,
				[css.grey]: grey,
				[css.text]: text,
				[css.slim]: slim,
				[css.small]: small,
				[css.rounded]: rounded,
				[css.outline]: outline,
				[css.busy]: busy,
			})}
			href={href}
			type={href ? void 0 : type}
			{...props}
		>
			{children}
		</El>
	);
}

import css from './style.module.scss';
import Image from 'next/image';
import { Emit, Events } from '../../util/Events';
import { gql } from '../../util/fetchGql';

export const LINE_ITEM_FRAGMENT = gql`
	fragment LineItem on VariantInterface {
		id
		title
		productTitle
		price
		priceAsCurrency
		salePrice
		salePriceAsCurrency
		... on products_Variant {
			image {
				url @transform(
					width: 160
					height: 160
				)
			}
		}
		product {
			... on products_Product {
				brands {
					title
				}
			}
		}
	}
`;

const z = s => s?.replace('.00', '');

export default function LineItem ({ id, qty, variant, readOnly = false }) {
	const onIncreaseQty = () => Emit(Events.IncreaseQty, id)
		, onDecreaseQty = () => Emit(Events.DecreaseQty, id);

	if (!variant) return null;

	return (
		<li className={css.lineItem}>
			{variant?.image?.length > 0 && (
				<Image unoptimized
					src={variant?.image?.[0]?.url}
					width={80}
					height={80}
				/>
			)}
			<div className={css.text}>
				<small>{variant?.product?.brands?.[0]?.title}</small>
				<strong>{variant.productTitle} - {variant.title}</strong>
				<span>
					{variant.salePriceAsCurreny === variant.priceAsCurrency ? (
						<>{z(variant.priceAsCurrency)}</>
					) : (
						<><del>{z(variant.salePriceAsCurreny)}</del> {z(variant.priceAsCurrency)}</>
					)}
				</span>
			</div>
			<div className={css.qty}>
				{readOnly ? (
					<span>{qty}</span>
				) : (
					<>
						<button
							title="Decrease Quantity"
							className={css.decrease}
							onClick={onDecreaseQty}
						/>
						<span>{qty}</span>
						<button
							title="Increase Quantity"
							className={css.increase}
							onClick={onIncreaseQty}
						/>
					</>
				)}
			</div>
		</li>
	);
}

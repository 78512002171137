import css from './style.module.scss';
import cls from '../../util/cls';
import { useMemo, useState } from 'react';
import tidyUri from '../../util/tidyUri';
import A from '../A';
import { AnimatePresence, motion } from 'framer-motion';

const NAV_ROOT = 'root';

const variants = {
	enter: direction => {
		return {
			x: direction > 0 ? '100vw' : '-100vw',
			opacity: 0
		};
	},
	center: {
		zIndex: 1,
		x: 0,
		opacity: 1
	},
	exit: direction => {
		return {
			zIndex: 0,
			x: direction < 0 ? '100vw' : '-100vw',
			opacity: 0
		};
	}
};

export default function MobileNav ({ nav, additionalRootItems = [], open = false }) {
	const [activeParent, setActiveParent] = useState(NAV_ROOT)
		, [direction, setDirection] = useState(1);

	const { byParent, byId } = useMemo(() => {
		if (!nav) return [];

		const byParent = {
			[NAV_ROOT]: [],
		};

		const byId = {};

		nav.forEach(item => {
			const hasChildren = item.children.length > 0;

			byParent[NAV_ROOT].push(item.id);

			byId[item.id] = {
				id: item.id,
				uri: tidyUri(item.uri),
				title: item.title,
				parent: NAV_ROOT,
				hasChildren,
			};

			if (hasChildren)
				byParent[item.id] = [];

			item.children.forEach(child => {
				const hasChildren = child.children.length > 0;

				byParent[item.id].push(child.id);

				byId[child.id] = {
					id: child.id,
					uri: child.uri ? tidyUri(child.uri) : null,
					title: child.title,
					parent: item.id,
					hasChildren,
				};

				if (hasChildren)
					byParent[child.id] = [];

				child.children.forEach(kid => {
					byParent[child.id].push(kid.id);

					byId[kid.id] = {
						id: kid.id,
						uri: tidyUri(kid.uri),
						title: kid.title,
						parent: child.id,
					};
				});
			});
		});

		additionalRootItems.forEach(item => {
			byParent[NAV_ROOT].push(item.uri);
			byId[item.uri] = {
				id: item.uri,
				uri: tidyUri(item.uri),
				title: item.title,
				parent: NAV_ROOT,
				additional: true,
			};
		});

		return { byParent, byId };
	}, [nav]);

	if (!byParent?.[activeParent]?.length)
		return null;

	const onArrowClick = (id, direction) => () => {
		setDirection(direction);
		setTimeout(() => setActiveParent(id), 0);
	};
	const parent = byId[activeParent] ?? null;

	return (
		<div className={cls(css.mobileNav, { [css.open]: open })}>
			<AnimatePresence>
				{parent && (
					<motion.button
						key={`parent_${parent.id}`}
						onClick={onArrowClick(parent.parent, -1)}
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
					>
						<span>&larr;</span>
						<span>{parent.title}</span>
					</motion.button>
				)}

				<motion.ul
					key={activeParent}
					custom={direction}
					variants={variants}
					initial="enter"
					animate="center"
					exit="exit"
					transition={{
						x: { type: "spring", stiffness: 300, damping: 30 },
						opacity: { duration: 0.2 }
					}}
				>
					{byParent[activeParent].map(id => {
						const item = byId[id];

						return (
							<li key={id} className={cls({ [css.additional]: item.additional || !item.hasChildren })}>
								{item.uri ? (
									<A href={item.uri}>{item.title}</A>
								) : (
									<button onClick={onArrowClick(item.id, 1)}>
										{item.title}
									</button>
								)}
								{item.hasChildren && (
									<button onClick={onArrowClick(item.id, 1)}>
										&rarr;
									</button>
								)}
							</li>
						);
					})}
				</motion.ul>
			</AnimatePresence>
		</div>
	);
}

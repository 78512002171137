import Cookies from 'js-cookie';

const sleep = async (dur = 250) => new Promise(resolve => {
	setTimeout(resolve, dur);
});

export const gql = (query, ...values) => {
	query = query.reduce((a, b, i) => a + b + (values[i] || ''), '')
		.replace(/[\r\n]/g, ' ')
		.replace(/\s+/g, ' ');

	if (query.trim().startsWith('fragment')) {
		// Prepend marker to fragment
		query += '#FRAG';

		// Ensure any nested fragments are also prepended
		query = query.replace(/(?!^)fragment/g, '#FRAG fragment');
	}

	return query;
}

const FRAGMENT_RX = /fragment\s+(\w+)\s+on\s+\w+\s+\{.*?(?=\s?#FRAG)/mg;

export default async function fetchGql (query, variables, ctx = null, returnErrors = false, noAuthRetry = false, isRetry = 0) {
	let url = process.env.NEXT_PUBLIC_GRAPH_API || 'https://dev.referenceaudio.co.uk/graph';

	if (ctx?.previewData)
		url += '?token=' + ctx.previewData.token;

	// Extract fragments and remove dupes
	const rawQuery = query
		, fragmentNames = [];

	query = query.replace(FRAGMENT_RX, '').replace(/\s#FRAG/g, '');

	let match;
	while ((match = FRAGMENT_RX.exec(rawQuery)) !== null) {
		const [fragment, name] = match;

		if (fragmentNames.indexOf(name) === -1) {
			fragmentNames.push(name);
			query += ' ' + fragment;
		}
	}

	const body = JSON.stringify({
		query,
		variables,
	});

	const headers = {
		'Content-Type': 'application/json',
	};

	const token = Cookies.get(process.env.sessionCookieName) ?? ctx?.req?.cookies?.[process.env.sessionCookieName];

	if (token && !noAuthRetry) headers['Authorization'] = `JWT ${token}`;

	let res, textResponse = null;

	try {
		res = await fetch(url, {
			method: 'POST',
			headers,
			body,
		});

		textResponse = await res.text();

		if (!textResponse) {
			if (returnErrors) return [null, 'Response was null'];
			throw new Error('Response was null');
		}

		const json = JSON.parse(textResponse);

		if (json.errors) {
			console.log(json.errors);
			if (returnErrors) return [json.data, json.errors];
			throw new Error('Failed to fetch API');
		}

		if (returnErrors)
			return [json.data, null];

		return json.data;
	} catch (e) {
		if (isRetry++ < 5) {
			console.log(res?.status, res?.statusText, ', queuing retry...',);
			await sleep(250 * isRetry);
			return await fetchGql(rawQuery, variables, ctx, returnErrors, noAuthRetry, isRetry);
		}

		console.log('Retry... ', res?.status, res?.statusText);

		throw e;
	}
}

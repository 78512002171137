import get from 'lodash.get';

export default function nestNav (flatNav) {
	const nest = (items, id = void 0) =>
		items
			.filter(item => get(item, 'parent.id') === id)
			.map(item => ({ ...item, children: nest(items, item.id) }));

	return nest(flatNav);
}

import css from './style.module.scss';
import Wrap from '../Wrap';
import A from '../A';
import Logomark from '../../svg/logomark.svg';
import PayVisa from '../../svg/pay-visa.svg';
import PayMastercard from '../../svg/pay-mastercard.svg';
import PayAmex from '../../svg/pay-amex.svg';
import PayPayPal from '../../svg/pay-paypal.svg';
import PayDeko from '../../svg/pay-deko.svg';
import cls from '../../util/cls';
import Arrow from '../../svg/arrow.svg';
import { gql } from '../../util/fetchGql';
import tidyUri from '../../util/tidyUri';
import Prose from '../Prose';
import Script from 'next/script';

export const FOOTER_FRAGMENT = gql`
	fragment Footer on Query {
		footer: globalSet (handle: "footer") {
			... on footer_GlobalSet {
				text
				label
				excerpt
			}
		}
		footerNav: nodes (navHandle: "footer") {
            id
            title
            uri: nodeUri
            parent {
                id
            }
		}
	}
`;

export default function Footer ({ nav, text, label, excerpt }) {
	return (
		<footer className={css.footer}>
			<div className={css.section}>
				<Wrap grid>
					<div className={css.left}>
						<A href="/" className={css.logo}>
							<Logomark />
						</A>

						<h5>Secure payments with</h5>
						<div className={css.cards}>
							<PayVisa />
							<PayMastercard />
							<PayAmex />
							{/*<PayPayPal />
							<PayDeko />*/}
						</div>
					</div>
					<div className={cls(css.right, css.newsletter)}>
						<p><em>{label}</em></p>
						<p><small>{excerpt}</small></p>

						{/*
						 ```
						 <form class="js-cm-form" id="subForm" action="https://www.createsend.com/t/subscribeerror?description=" method="post" data-id="5B5E7037DA78A748374AD499497E309E7677A8247E942CEC21FCCD76FB265A3B551DC7225C27E7838AE2C97E796C7877F6E6075FC58E2DFF21A4BB0B2030916F">
						 <label>Name </label>
						 <input aria-label="Name" id="fieldName" maxlength="200" name="cm-name">
						 <label>Email </label>
						 <input autocomplete="Email" aria-label="Email" class="js-cm-email-input qa-input-email" id="fieldEmail" maxlength="200" name="cm-byutikl-byutikl" required="" type="email">
						 <button type="submit">Subscribe</button>
						 </form>
						 <script type="text/javascript" src="https://js.createsend1.com/javascript/copypastesubscribeformlogic.js"></script>
						 ```
						*/}

						<form
							className="js-cm-form"
							id="subForm"
							action="https://www.createsend.com/t/subscribeerror?description="
							method="post"
							data-id="5B5E7037DA78A748374AD499497E309E7677A8247E942CEC21FCCD76FB265A3B551DC7225C27E7838AE2C97E796C7877F6E6075FC58E2DFF21A4BB0B2030916F"
						>
							<label>
								<span>Name</span>
								<input
									type="text"
									placeholder="What's your name?"
									required
									aria-label="Name" id="fieldName" maxLength="200" name="cm-name"
								/>
							</label>
							<label>
								<span>Email</span>
								<input
									type="email"
									placeholder="And your email address?"
									required
									autoComplete="Email" aria-label="Email" className="js-cm-email-input qa-input-email" id="fieldEmail" maxLength="200" name="cm-byutikl-byutikl"
								/>
							</label>
							<button title="Subscribe!" type="submit">
								<Arrow />
							</button>
						</form>
						<Script
							src="https://js.createsend1.com/javascript/copypastesubscribeformlogic.js"
							strategy="lazyOnload"
						/>
					</div>
				</Wrap>
			</div>
			<div className={css.section}>
				<Wrap grid>
					<Prose className={cls(css.left, css.about)}>
						<h5>About Reference Audio</h5>
						{text}
					</Prose>
					<nav className={cls(css.right, css.nav)}>
						{nav.map(item => (
							<ul key={item.id}>
								<li><h5>{item.title}</h5></li>
								{item.children.map(item => (
									<li key={item.id}><A href={tidyUri(item.uri)}>{item.title}</A></li>
								))}
							</ul>
						))}
					</nav>
				</Wrap>
			</div>
		</footer>
	);
}

import '../styles/globals.scss';
import dynamic from 'next/dynamic';
import Header, { HEADER_FRAGMENT } from '../components/Header';
import Footer, { FOOTER_FRAGMENT } from '../components/Footer';
import Cart from '../components/Cart';
import fetchGql, { gql } from '../util/fetchGql';
import nestNav from '../util/nestNav';
import { ANNOUNCEMENT_FRAGMENT } from '../components/Announcement';
import Search from '../components/Search';
import Head from 'next/head';
import Tracking, { reportWebVitals } from '../components/Tracking';
import { AUTH_LAYOUT_FRAGMENT } from '../components/AuthLayout';
import DataContext from '../contexts/DataContext';
import ItemsProvider from '../components/ItemsProvider';
import { usePreserveScroll } from '../hooks/usePreserveScroll';
const Announcement = dynamic(() => import('../components/Announcement'), { ssr: false });

export { reportWebVitals };

function MyApp ({ Component, pageProps, mainNav, footerNav, announcement, footer, authLayout }) {
	usePreserveScroll();

	return (
		<DataContext.Provider value={{ authLayout }}>
			<ItemsProvider>
				<Head>
					<link rel="shortcut icon" href="/favicon.svg" />
				</Head>
				<Announcement {...announcement} />
				<Header nav={mainNav} />
				<Cart />
				<Search />
				<Component {...pageProps} />
				<Footer
					nav={footerNav}
					{...footer}
				/>
				<Tracking />
			</ItemsProvider>
		</DataContext.Provider>
	);
}

MyApp.getInitialProps = async ({ ctx }) => {
	const data = await fetchGql(gql`
        query GetBaseData {
            ...Header
	        ...Footer
	        ...Announcement
	        ...AuthLayout
        }
        ${HEADER_FRAGMENT}
        ${FOOTER_FRAGMENT}
        ${ANNOUNCEMENT_FRAGMENT}
        ${AUTH_LAYOUT_FRAGMENT}
	`, null, ctx);

	return {
		mainNav: nestNav(data.mainNav),
		footerNav: nestNav(data.footerNav),
		announcement: data.announcement,
		footer: data.footer,
		authLayout: data.authLayout,
	};
};

export default MyApp;

import css from './style.module.scss';
import ReactModal from 'react-modal';
import { useEffect, useMemo, useState } from 'react';
import { Events, Listen } from '../../util/Events';
import cls from '../../util/cls';
import debounce from '../../util/debounce';
import useFetch from '../../hooks/useFetch';
import { gql } from '../../util/fetchGql';
import Image from 'next/image';
import A from '../A';
import { useRouter } from 'next/router';

export default function Search () {
	const router = useRouter();
	const [isOpen, setIsOpen] = useState(false)
		, [query, setQuery] = useState('');

	const onRequestClose = () => setIsOpen(false)
		, onSearchInput = e => setQuery(e.target.value);

	useEffect(() => {
		const onRouteChange = () => setIsOpen(false);
		router.events.on('routeChangeStart', onRouteChange);
		return () => {
			router.events.off('routeChangeStart', onRouteChange);
		};
	});

	useEffect(() => Listen(
		Events.OpenSearch,
		(query = '') => {
			if (query !== Events.OpenSearch) setQuery(query);
			setIsOpen(true);
		}
	), []);

	const { data, loading } = useFetch(gql`
		query Search ($query: String) {
			entries (
				search: $query
				limit: 5
				section: ["brand", "productCategory"]
			) {
				sectionHandle
				title
				uri
				searchScore
				
				... on brand_default_Entry {
					images (limit: 1) {
						url @transform(
							width: 128
							height: 128
						)
					}
				}
				
				... on productCategory_default_Entry {
					banner (limit: 1) {
						... on banner_BlockType {
							image (limit: 1) {
                                url @transform(
                                    width: 128
                                    height: 128
                                )
							}
						}
					}
				}
			}
			products (
				search: $query
				limit: 5
				isHidden: false
			) {
				title
				uri
				searchScore
				defaultPriceAsCurrency
				
				... on products_Product {
					brands (limit: 1) {
						title
					}
					variants {
						... on products_Variant {
							image (limit: 1) {
                                url @transform(
                                    width: 128
                                    height: 128
                                )
							}
						}
					}
				}
			}
		}
	`, { query }, `search_${encodeURI(query)}`, query.trim() === '');

	const results = useMemo(() => {
		if (!data) return [];

		return [
			...data.entries.map(entry => {
				let thumbnail;

				if (entry.sectionHandle === 'brand') {
					thumbnail = entry.images[0]?.url;
				} else {
					thumbnail = entry.banner[0]?.image?.[0]?.url;
				}

				return {
					score: entry.searchScore,
					title: entry.title,
					uri: entry.uri,
					thumbnail,
				};
			}),
			...data.products.map(product => {
				return {
					score: product.searchScore,
					title: product.title,
					uri: product.uri,
					thumbnail: product.variants[0]?.image?.[0]?.url,
					brand: product.brands[0]?.title,
					meta: product.defaultPriceAsCurrency,
				};
			}),
		].sort((a, b) => b.score - a.score).slice(0, 5);
	}, [data]);

	const hasResults = query.trim() !== '' && !loading && results.length > 0;

	return (
		<ReactModal
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			className={cls(css.modal, { [css.hasResults]: hasResults })}
			overlayClassName={css.overlay}
			closeTimeoutMS={300}
		>
			<div className={css.prompt}>Click anywhere to close</div>
			<label className={css.search}>
				<input
					type="search"
					autoFocus
					placeholder="Start typing to search…"
					onInput={debounce(onSearchInput)}
					defaultValue={query}
				/>
			</label>
			{hasResults && (
				<ul className={css.results}>
					{results.map(result => (
						<li key={result.uri}>
							<A href={result.uri} className={css.result}>
								<div className={css.image}>
									{result.thumbnail && (
										<Image unoptimized
											src={result.thumbnail}
											width={64}
											height={64}
										/>
									)}
								</div>
								<div className={css.text}>
									{result.brand && <small>{result.brand}</small>}
									{result.title}
								</div>
								{result.meta && (
									<div className={css.meta}>{result.meta}</div>
								)}
							</A>
						</li>
					))}
				</ul>
			)}
		</ReactModal>
	);
}

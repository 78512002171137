import { useEffect, useState } from 'react';
import css from './style.module.scss';
import Prose from '../Prose';
import Modal from '../Modal';
import { Events, Listen } from '../../util/Events';
import Button from '../Button';
import cls from '../../util/cls';
import { useRouter } from 'next/router';
import Items from './Items';

export const CART_KEY = 'reference_audio_cart';


export default function Cart () {
	const router = useRouter();
	const [open, setOpen] = useState(false)
		, [busy, setBusy] = useState(false);

	useEffect(() => Listen(
		Events.OpenCart,
		() => setOpen(true)
	));

	const onRequestClose = () => setOpen(false);

	const onCheckoutClick = () => {
		router.push('/checkout');
		onRequestClose();
	};

	return (
		<Modal
			right
			isOpen={open}
			className={css.cart}
			onRequestClose={onRequestClose}
		>
			<Prose El="header">
				<h2>Basket</h2>
			</Prose>

			<Items setBusy={setBusy} />

			<footer className={cls(css.footer, { [css.busy]: busy })}>
				<Button wide blue onClick={onCheckoutClick}>Go to Checkout</Button>
			</footer>
		</Modal>
	);
}

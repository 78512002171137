import css from './style.module.scss';
import Wrap from '../Wrap';
import A from '../A';
import Logo from '../../svg/logo.svg';
import IconSearch from '../../svg/icon-search.svg';
import IconAccount from '../../svg/icon-account.svg';
import IconCart from '../../svg/icon-cart.svg';
import cls from '../../util/cls';
import { gql } from '../../util/fetchGql';
import tidyUri from '../../util/tidyUri';
import Logomark from '../../svg/logomark.svg';
import { Emit, Events } from '../../util/Events';
import { useEffect, useRef, useState } from 'react';
import MobileNav from '../MobileNav';
import { useRouter } from 'next/router';

export const HEADER_FRAGMENT = gql`
	fragment Header on Query {
        mainNav: nodes (navHandle: "main") {
            id
            title
            uri: nodeUri
            parent {
                id
            }
        }
	}
`;

export default function Header ({ nav }) {
	const router = useRouter();
	const toggle = useRef();
	const [navOpen, setNavOpen] = useState(false)
		, [topPull, setTopPull] = useState(0);

	const onToggleClick = () => setNavOpen(o => {
		document.body.style.overflow = o ? '' : 'hidden';

		if (o) setTopPull(0);
		else setTopPull((toggle.current.parentNode.getBoundingClientRect().top - 14) * -1);

		return !o;
	});
	const onCartClick = () => Emit(Events.OpenCart);
	const onSearchClick = () => Emit(Events.OpenSearch);

	useEffect(() => {
		const onRouteChange = () => {
			document.body.style.overflow = '';
			setNavOpen(false);
			setTopPull(0);
		};

		router.events.on('routeChangeComplete', onRouteChange);

		return () => {
			router.events.off('routeChangeComplete', onRouteChange);
		};
	}, []);

	return (
		<Wrap wide El="header" className={css.header} style={{ '--top-pull': `${topPull}px` }}>
			<A href="/" className={css.logo}>
				<Logo />
				<Logomark />
			</A>
			<nav className={css.primary}>
{/* 				<ul className={css.nav}>
					<li><A href="tel:01376526070">01376 526 070</A></li>
					<li><A href="mailto:sales@referenceaudio.co.uk">sales@referenceaudio.co.uk</A></li>
				</ul> */}

				<ul className={css.nav}>
					{nav.map(item => (
						<li key={item.id}>
							<A href={tidyUri(item.uri)}>{item.title}</A>
							{item.children.length > 0 && (
								<ul className={css.subNav}>
									{item.children.map(item => (
										<li key={item.id}>
											<ul>
												<li>
													<h5>{item.title}</h5>
													{item.children.length > 0 && (
														<ul>
															{item.children.map(item => (
																<li key={item.id}>
																	<A href={tidyUri(item.uri)}>{item.title}</A>
																</li>
															))}
														</ul>
													)}
												</li>
											</ul>
										</li>
									))}
								</ul>
							)}
						</li>
					))}
				</ul>
			</nav>
			<nav>
				<ul className={cls(css.nav, css.blue)}>
					<li><A href="/brands">Brands</A></li>
					<li><A href="/news">News</A></li>
					<li><A href="/contact">Contact</A></li>
				</ul>
			</nav>
			<div className={css.controls}>
				<button type="button" onClick={onSearchClick}><IconSearch /></button>
				<A href="/account"><IconAccount /></A>
				<button type="button" onClick={onCartClick}><IconCart /></button>
			</div>

			<button
				ref={toggle}
				className={cls(css.toggle, { [css.open]: navOpen })}
				onClick={onToggleClick}
			>
				<span />
			</button>
			<MobileNav
				open={navOpen}
				nav={nav}
				additionalRootItems={[
					{ uri: '/brands', title: 'Brands' },
					{ uri: '/news', title: 'News' },
				]}
			/>
		</Wrap>
	);
}

import Head from 'next/head';
import { gql } from '../util/fetchGql';
import unescape from '../util/unescape';

export const SEO_FRAGMENT = gql`
    fragment Seo on Ether_SeoData {
        title
        description
        social {
            twitter {
                title
                description
                image @transform(width:1200, height:600) {
                    url
                }
            }
            facebook {
                title
                description
                image @transform(width:1200, height:630) {
                    url
                }
            }
        }
    }
`;

export default function Seo ({ seo, title }) {
	const fb = seo ? seo.social.facebook : null
		, tw = seo ? seo.social.twitter  : null;

	return seo ? (
		<Head>
			<title>{unescape(seo.title)}</title>
			<meta name="description" content={seo.description} />

			<meta property="og:type" content="website" />
			<meta property="og:title" content={fb.title} />
			<meta property="og:image" content={fb.image ? fb.image.url : ''} />
			<meta property="og:image:width" content="1200" />
			<meta property="og:image:height" content="630" />
			<meta property="og:description" content={fb.description} />

			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:title" content={tw.title} />
			<meta name="twitter:description" content={tw.description} />
			<meta name="twitter:image" content={tw.image ? tw.image.url : ''} />
			
			<meta name="google-site-verification" content="dP3fCLv2nzv4PfoePI_uN8hUMjSU70oujQ_BaG5KNNg" />
		</Head>
	) : (
		<Head>
			<title>{title}</title>
		</Head>
	);
}
